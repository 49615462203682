import Button from './Button';
import styled from 'styled-components';
import {useConfig} from './ConfigProvider';
import CheckMark from './CheckMark';
import "./Choice.scss";

export default function CounterChoice({value, selected, add, useImage=false, imageBaseURL}){
  const config = useConfig();

  let price = '';
  let priceLabel = '';

  const description = (value.description || '').split('\n');
  if( description.length > 0 )
    price = description.shift();
  if( description.length > 0)
    priceLabel = description.shift();


  let count = 0;
  if( selected[value['@id']] ) {
    count = selected[value['@id']].count;
  }

  const countStyle = {padding:'1rem'};
  if( count > 0) {
    countStyle.fontWeight= 'bold';
  }

  if( useImage && imageBaseURL && value?.properties?.thumbnailImage?.publicId) {
    const img = `${imageBaseURL}${value.properties.thumbnailImage.publicId}`
    return (
      <StyledArticle className="OptionPreview" config={config.choice} selected={count > 0}>
        <header style={{textAlign:'center'}}>
          <h3>{value.name}</h3>
          <strong>{price}</strong>
          <strong>{priceLabel}</strong>
        </header>
        <section style={{display:'flex', flexGrow:1}}>
          <img src={img} alt={(value.name||'').slice(0,10)} style={{paddingBottom: '1rem'}}/>
          {useImage !== 'only' && (
            <ul>
              {description.filter(e=>e).map((d, i) => (
                <li key={i} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <CheckMark/>
                  <span style={{textAlign:'left'}}>{d}</span>
                </li>
              ))}
            </ul>
          )}
        </section>
        <footer>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <Button variant='textOnly' style={{padding:'1rem', fontSize:'2rem'}} onClick={e=> {e.preventDefault(); add(value, -1)}}>-</Button>
            <div style={countStyle}>{count}</div>
            <Button variant='textOnly' style={{padding:'1rem', fontSize:'2rem'}} onClick={e=> {e.preventDefault(); add(value, +1)}}>+</Button>
          </div>
        </footer>
      </StyledArticle>
    );
  }

  return (
    <StyledArticle className="OptionPreview" config={config.choice} selected={count > 0}>
      <header style={{textAlign:'center'}}>
        <h3>{value.name}</h3>
        <strong>{price}</strong>
        <strong>{priceLabel}</strong>
      </header>
      <section>
        <ul>
          {description.map((d, i) => (
            <li key={i} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <CheckMark />
              <span style={{textAlign:'left'}}>{d}</span>
            </li>
          ))}
        </ul>
      </section>
      <footer>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <Button variant='textOnly' style={{padding:'1rem', fontSize:'2rem'}} onClick={e=> {e.preventDefault(); add(value, -1)}}>-</Button>
          <div style={countStyle}>{count}</div>
          <Button variant='textOnly' style={{padding:'1rem', fontSize:'2rem'}} onClick={e=> {e.preventDefault(); add(value, +1)}}>+</Button>
        </div>
      </footer>
    </StyledArticle>
  );
}


const StyledArticle = styled.article`
  background-color: ${props => props.config?.background || 'white'};
  border: ${props => props.selected
     ? `2px solid ${props.config?.border_selected || 'red'}`
     : `2px solid ${props.config?.border || 'green'}`
  };
  > header {
    h3 {
      color: ${props => props.config?.nameColor || '#e63f49' }
    }
  }
  > footer {
    background-color: ${props => props.selected
      ? (props.config?.footerBg || 'yellow')
      : (props.config?.footerBg_selected || 'red')};
  }

  input {
    background-color: ${props => props.selected ? (props.config?.checkboxSelected || 'black') : ''}
  }
`;