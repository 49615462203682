function request({path, method, body, headers, options, query, origin} ) {

	const _headers = {...headers} || {};
	if ( options?.multipart ) {
	} else {
		_headers['content-type'] = 'application/json';
	}
	let _body = body;

	if ( _body && !options?.multipart) {
		_body = JSON.stringify(_body);
	}

	let fullPath = (origin || window.location.origin) + path;
	if (query) {
		const sp = new URLSearchParams(query);
		sp.sort();
		fullPath += `?${sp.toString()}`;
	}

	const controller = new AbortController();

	const request = fetch(fullPath, { body:_body, headers:_headers, method, signal: controller.signal})
	.then((response) => {
		if (!response) Promise.reject(null);
		if (!response.ok) {
			return response.json().then((json) => {
				return Promise.reject(json)
				.catch( e => {
					console.error(`[baseApi] Cannot parse json response from ${method} ${path}, status:${response.status}`, e);
					throw e;
				});
			});
		}

		if (response.status === 204) return null;

		return response.json()
		.catch( e => {
			console.error(`[baseApi] Cannot parse json response from ${method} ${path}, status:${response.status}`, e);
			throw e;
		});
	});

	return [request, controller];
}

const Api = {
	DELETE: (params) => request({...params, method:'DELETE'}),
	GET: (params) => request({...params, method:'GET'}),
	PATCH: (params) => request({...params, method:'PATCH'}),
	POST: (params) => request({...params, method:'POST'}),
	PUT: (params) => request({...params, method:'PUT'})
};

export default Api;
