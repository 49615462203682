import {Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";
import dayjs from 'dayjs';
import {useConfig} from './ConfigProvider';
import Api from "./baseApi";
import {useTunnelData} from "./TunnelContext";
import StepHeader from "./StepHeader";
import Step from './steps/Step';

const PRODUCTS_TTL = 1000 * 60 * 5;

export default function ProductsLoader({children, allowNoUser}) {
  const [step, setStep] = useState("PRODUCTS_LOAD");
  const config = useConfig();
  const {tunnel, setTunnel} = useTunnelData();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (!tunnel.club) return;
    setStep('PRODUCTS_LOAD')

    if ( tunnel.products && Date.now() < tunnel.products.date + PRODUCTS_TTL) {
      setStep('OK');
      return;
    }
    const [query] = Api.GET({
      path: '/api/abo/resamania2/products',
      query: {
        msdsId: tunnel.club.msdsId, tunnelUid: tunnel.uid,
        context: config.productsLoader.contextFilter, filterByClubIncluded: !!config.productsLoader.filterByClubIncluded
      }
    });
    query
    .then(ret => {
      let valid = ret.filter( p => {
        if( p.offer.validFrom && new Date(p.offer.validFrom).getTime() > Date.now())
          return false;
        if( p.offer.validThrough && new Date(p.offer.validThrough).getTime() < Date.now())
          return false;


        if( tunnel.contactResa && p.offer.context?.contactAgeIncluded?.max)  {
          const age = dayjs(new Date()).diff(tunnel.contactResa.birthdate, 'year');
          if( age > p.offer.context.contactAgeIncluded.max)
            return false;
        }
        if( tunnel.contactResa && p.offer.context?.contactAgeIncluded?.min)  {
          const age = dayjs(new Date()).diff(tunnel.contactResa.birthdate, 'year');
          if( age < p.offer.context.contactAgeIncluded.min)
            return false;
        }
        return true;
      });
      valid = valid.filter(p=>p.highlighted).concat(valid.filter(p=>!p.highlighted))
      let plans = valid.filter( p => p.type === 'subscription' || p.type === 'counter');
      if( config.plans.order ) {
        plans = config.plans.order(plans)
      }

      const options = valid.filter( p => p.type === 'subscription_option');
      options.forEach( o => {
        const mandatoryFor = ret.map( p => {
          if( p.type !== 'subscription' && p.type !== 'counter')
            return false;
          const isAMandatoryOption = p.productBehaviors.some( pb => {
            if( !pb?.configuration?.linkedOptions)
              return false;
            return pb.configuration.linkedOptions.indexOf( o.offer.product ) !== -1
          })

          if( isAMandatoryOption )
            return p['@id'];
        }).flat().filter(e => e)
        o.mandatoryFor = mandatoryFor;
        console.debug('option', o, 'is mandatoryFor ', mandatoryFor)
      })

      const regulars = valid.filter( p => p.type === 'regular');
      setTunnel({...tunnel, products: {
        date: Date.now(),
        clubMsdsId: tunnel.club.msdsId,
        plans, options, regulars
      }})
      setStep("OK");
    })
    .catch(err => {
      setStep('PRODUCTS_LOAD_ERROR')
      console.error(err);
    });

  }, [ tunnel.club?.msdsId, tunnel.uid ]);

  if (!tunnel.club )
    return <Redirect push to={'/'}/>

  if( !tunnel.user && ( !config.plans.beforeUser && !allowNoUser) )
     return <Redirect push to={'/user'}/>

  if ( step === "PRODUCTS_LOAD"){
    return (
      <Step classe="Plans" step="Formules d'abonnement & options" loading={config.loadingTextOffersLoad} />
    );
  }

  if ( step === 'PRODUCTS_LOAD_ERROR' ) {
    return (
      <Step classe="Plans" step="Formules d'abonnement & options">
        <header>
          <StepHeader text={config.productsLoader.errorTitle}/>
        </header>
        <main style={{textAlign:'center'}}>{config.productsLoader.errorText}</main>
      </Step>
    );
  }

  return children;
};
