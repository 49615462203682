import styled from 'styled-components';
import { darken, lighten } from 'polished'
import {useHistory} from 'react-router-dom';

import {useConfig} from './ConfigProvider';


export default function Button({onClick, type, to, disabled=false, style={}, variant='', children} ){
	const history = useHistory();
	const {theme} = useConfig();
	const _onClick = e => {
		e.preventDefault();
		if( disabled )
			return;

		if( to )
			return history.push(to);

		onClick && onClick(e);
	};

	return (
		<StyledButton type={type} theme={theme} variant={variant} disabled={disabled} onClick={_onClick} style={style}>{children}</StyledButton>
	);
}

const StyledButton = styled.button`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	transition: all .3s ease;
	align-items: center;
	justify-content: center;
	height: auto;
	font-size: 1rem;
	font-family: ${ (props) => props.theme.fontFamily };
	font-weight: 800;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	userSelect: 'none';
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	color: #555555;
	padding: 0.5rem;


	${props => {
		switch(props.variant) {
			case 'primary':
				return `
					text-transform: uppercase;
					border:1px solid ${props.theme.buttonPrimary.color};
					color: ${props.theme.buttonPrimary.text};
					background-color: ${props.theme.buttonPrimary.color};
					&:hover{
						border-color: ${darken(0.15, props.theme.buttonPrimary.color)};
						background-color: ${darken(0.15, props.theme.buttonPrimary.color)};
					}
					&:disabled {
						border: 1px solid ${lighten(0.2, '#b8b8b8')};
						background-color: ${lighten(0.2, '#b8b8b8')};
						color: #b8b8b8;
						cursor: default;
					}
				`
			case 'other' : 
				return `
					text-transform: uppercase;
					border:1px solid ${props.theme.buttonOther.color};
					color: ${props.theme.buttonOther.text};
					background-color: ${props.theme.buttonOther.color};
					&:hover{
						border-color: ${darken(0.15, props.theme.buttonOther.color)};
						background-color: ${darken(0.15, props.theme.buttonOther.color)};
					}
					&:disabled {
						border: 1px solid ${lighten(0.2, '#b8b8b8')};
						background-color: ${lighten(0.2, '#b8b8b8')};
						color: #b8b8b8;
						cursor: default;
					}
				`
			case 'secondary':
				return `
					text-transform: uppercase;
					border:1px solid ${props.theme.buttonSecondary.color};
					color: ${props.theme.buttonSecondary.text};
					background-color: ${props.theme.buttonSecondary.color};
				`
			case 'textOnly':
				return `
					color: ${props.theme.buttonPrimary.color};
					&:hover{
						color: ${darken(0.15, props.theme.buttonPrimary.color)};
					}
				`
			default: 
				return '';
		}
	}};
`;
