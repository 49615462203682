import {Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {useConfig} from '../ConfigProvider';
import {useTunnelData} from "../TunnelContext";
import StepHeader from "../StepHeader";
import Step from './Step';
import OfferSelect from './OfferSelect';
import OptionsSelect from './OptionsSelect';
import RegularsSelect from './RegularsSelect';
import CartSummary from '../CartSummary';
import ProductsLoader from '../ProductsLoader';

import "./Plans.scss";

export default function Plans() {
  return <ProductsLoader><PlansChoice/></ProductsLoader>
}

function PlansChoice() {
  const [step, setStep] = useState("OFFER");
  const [errorMsg, errorMsgSet] = useState(false);
  const config = useConfig();

  const {tunnel, createSale} = useTunnelData();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const doCreateSale = e => {
    if( config.plans.beforeUser ) {
      setStep('USER')
      return 
    }
    const previousStep = step;
    errorMsgSet(false);
    setStep("CREATE")
    createSale()
    .then(ret => {
      if( config.recap) {
        setStep("RECAP");
        return;
      }
      setStep('SEPA');
    })
    .catch(err => {
      errorMsgSet(config.errorCode[err.errorCode] || err.userMsg || "Une erreur est survenue");
      setStep(previousStep)
      console.error(err);
    });
  };

  if (!tunnel.club )
    return <Redirect push to={'/'}/>

  if( !tunnel.user && !config.plans.beforeUser )
     return <Redirect push to={'/user'}/>

  const {plans, options, regulars} = tunnel.products;
  if( !plans.length ) {
    return (
      <Step classe="Plans" step="Formules d'abonnement & options">
        <header>
          <StepHeader text={config.plans.noPlanFoundTitle}/>
        </header>
        <div style={{padding:'1rem', textAlign:'center'}}>
          {config.plans.noPlanFoundTexts.map((t, i) => <p key={i}>{t}</p>)}
        </div>
      </Step>
    ); 
  }

  if (step === "USER") {
    return <Redirect push to={'/user'} />;
  }


  let nextStep = () => { throw new Error('No next step') };
  if( step === 'OFFER') {
    if (options?.length )
      nextStep = () => setStep('OPTIONS');
    else if (regulars?.length && !!config.regulars )
      nextStep = () => setStep('REGULARS');
    else
      nextStep = doCreateSale;
  } else if ( step === 'OPTIONS') {
    if (regulars?.length && !!config.regulars )
      nextStep = () => setStep('REGULARS');
    else
      nextStep = doCreateSale;
  } else if ( step === 'REGULARS') {
        nextStep = doCreateSale;
  } else if( step === 'RECAP') {
        nextStep = () => setStep('SEPA');
  } else if ( step === 'CREATE' ) {
    //There is no nextStep for thos
  } else if ( step === 'SEPA' ) {
    //There is no nextStep for thos
  } else {
    console.warn('Cannot create nextStep for step ===', step);
  }

  if( step === 'OFFER') {
    return <OfferSelect plans={plans} goNext={ nextStep } errorMsg={errorMsg} errorMsgSet={errorMsgSet} />
  }

  if( step === 'OPTIONS' ){
    return <OptionsSelect choices={options} goNext={ nextStep } errorMsg={errorMsg} errorMsgSet={errorMsgSet}/>;
  }

  if( step === 'REGULARS' ){
    return <RegularsSelect choices={regulars} goNext={ nextStep } errorMsg={errorMsg} errorMsgSet={errorMsgSet}/>;
  }

  if( step === 'RECAP' ){
    return <CartSummary goNext={ nextStep } errorMsg={errorMsg} errorMsgSet={errorMsgSet}/>;
  }

  if (step === "CREATE") {
    return <Step classe="Plans" step="Formules d'abonnement & options" loading={config.loadingTextCartCreation}/>
  }

  if (step === "SEPA")
    return <Redirect push to={'/sepa'} />;

  console.error('What to do with step', step)
  return null;
};
