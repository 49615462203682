import styled from 'styled-components';
import {useConfig} from './ConfigProvider';
import CheckMark from './CheckMark';
import {useTunnelData} from "./TunnelContext";

import "./Choice.scss";

export default function Choice({value, selected, onSelect, useImage=false, imageBaseURL}){
  const config = useConfig();
  const {tunnel} = useTunnelData();
  let price = '';
  let priceLabel = '';

  const description = (value.description || '').split('\n');
  if( description.length > 0 )
    price = description.shift();

  if( description.length > 0)
    priceLabel = description.shift();

  const found = selected.find(f => f['@id'] === value['@id']);
  const isSelected = !!found;
  const mandatory = isSelected && !!found.mandatory;
  const onClick = e => {
    if(mandatory || !onSelect)
      return
    onSelect(value);
  }

  if( useImage && (tunnel.club?.imageBaseURL || imageBaseURL) && value?.properties?.thumbnailImage?.publicId) {
    const img = `${tunnel.club?.imageBaseURL || imageBaseURL}${value.properties.thumbnailImage.publicId}`
    return (
    <StyledArticle selected={isSelected} className="OptionPreview" config={config.choice} onClick={onClick}>
      <header style={{textAlign:'center'}}>
        <h3>{value.name}</h3>
        <strong>{price}</strong>
        <strong>{priceLabel}</strong>
      </header>
      <section style={{display:'flex', flexGrow:1}}>
        <img src={img} alt={(value.name||'').slice(0,10)} style={{paddingBottom:'1rem'}}/>
        {useImage !== 'only' && (
            <ul>
              {description.map((d, i) => (
                <li key={i} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <CheckMark/>
                  <span style={{textAlign:'left'}}>{d}</span>
                </li>
              ))}
            </ul>
          )}
      </section>
      <footer>
        {!mandatory && onSelect && <label>
          <input checked={isSelected} type="checkbox" onChange={onClick}/>
          <span>{isSelected ? config.choice.selectedLabel : config.choice.selectLabel}</span>
        </label>}
        {!!mandatory &&  onSelect && <label>
          <span>{config.choice.mandatoryLabel}</span>
        </label>}
      </footer>
    </StyledArticle>
  );
  }


  return (
    <StyledArticle selected={isSelected} className="OptionPreview" config={config.choice}  onClick={onClick}>
      <header style={{textAlign:'center'}}>
        <h3>{value.name}</h3>
        <strong>{price}</strong>
        <strong>{priceLabel}</strong>
      </header>
      <section>
        <ul>
          {description.filter(e=>e).map((d, i) => (
            <li key={i} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <CheckMark />
              <span style={{textAlign:'left'}}>{d}</span>
            </li>
          ))}
        </ul>
      </section>
      {onSelect && (
        <footer>
          {!mandatory && <label>
            <input checked={isSelected} type="checkbox" onChange={onClick}/>
            <span>{isSelected ? config.choice.selectedLabel : config.choice.selectLabel}</span>
          </label>}
          {!!mandatory && <label>
            <span>{config.choice.mandatoryLabel}</span>
          </label>}
        </footer>
      )}
    </StyledArticle>
  );
}

const StyledArticle = styled.article`
  background-color: ${props => props.config?.background || 'white'};
  border: ${props => props.selected
     ? `2px solid ${props.config?.border_selected || 'red'}`
     : `2px solid ${props.config?.border || 'green'}`
  };
  > header {
    h3 {
      color: ${props => props.config?.nameColor || '#e63f49' }
    }
  }
  > footer {
    background-color: ${props => props.selected
      ? (props.config?.footerBg || 'yellow')
      : (props.config?.footerBg_selected || 'red')};
  }

  input {
    background-color: ${props => props.selected ? (props.config?.checkboxSelected || 'black') : ''}
  }
`;