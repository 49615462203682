import {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import dayjs from 'dayjs';
import {useConfig} from '../ConfigProvider';
import ClubPreview from "../ClubPreview";
import { useTunnelData } from "../TunnelContext";
import Api from "../baseApi";
import LabelInputText from '../LabelInputText'
import StepHeader from "../StepHeader";
import Button from "../Button";
import Step from './Step';
import AutoComplete from 'react-google-autocomplete'
import "./UserForm.scss";
import Confirm from '../Confirm';
import StyledStepFooter from '../StyledStepFooter';
import CartSummary from '../CartSummary';
import ProductsLoader from '../ProductsLoader';
import Choice from '../Choice';
import PhoneInput from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css'
import './Phone.css'

export default function UserFormSpecific() {
  const config = useConfig();
  const C = config.steps.userForm;
  if( C.displayFirstOffer ) {
    return (
      <ProductsLoader allowNoUser={true}>
        <UserFormWithOffer />
      </ProductsLoader>
    )
  }
  return <UserForm/>
}

function UserFormWithOffer() {
  const { tunnel } = useTunnelData();
  if(tunnel.club.offer_url) {
    return <div>
      <UserForm offer_url={tunnel.club.offer_url} /> 
    </div>
  }
  const offer = tunnel.products.plans[0];
  return (
    <div>
      <UserForm offer={offer} /> 
    </div>
  )
}

function UserForm({offer, offer_url}){
    const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      phone:'',
    },
    mode: 'onChange',
  });

  const config = useConfig();
  const C = config.steps.userForm;
  const { tunnel, updateTunnelUser, updateTunnelContactResa, createSale } = useTunnelData();
  const [ step, setStep ] = useState('FORM_1');
  const [errors, setErrors] = useState([]);
  const [errorMsg, errorMsgSet] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step]);

  const [phone] = watch(['phone']);

  const onChange = (ev) => {
    const {name, value} = ev.target;
    if( name === 'optin') {
      const update = { optin: !tunnel.user?.optin }
      updateTunnelUser(update);
    } else {
      const update = { [name]: value }
      updateTunnelUser(update);
    }
    errorMsgSet(false)
    setErrors(errors.filter( e => e !== name))
  };

  useEffect( () => {
    onChange({target: {name:"phone", value: phone} });
  }, [phone]);

  if (!tunnel.club) return <Redirect to={'/'} />;

  const findInGooglePlace = (place, type) => {
    const compo = place.address_components.find( c => c.types.indexOf(type) !== -1);
    return compo?.long_name;
  }

  const addressSelected = googlePlace => {
    const street_number = findInGooglePlace(googlePlace, 'street_number');
    const route = findInGooglePlace(googlePlace, 'route');
    const postal_code = findInGooglePlace(googlePlace, 'postal_code');
    const locality = findInGooglePlace(googlePlace, 'locality');
    let country = findInGooglePlace(googlePlace, 'country');
    if( country === 'La Réunion' || country === 'Guadeloupe')
        country = 'France';

    updateTunnelUser({
      addressFull: googlePlace.formatted_address,
      address: [street_number, route].filter(e=>e).join(" "),
      address2: '',
      zipcode: postal_code,
      city: locality,
      country: country,
    });
    if( !postal_code ) {
      if(!street_number)
        errorMsgSet("Incluez le numéro de rue dans votre adresse")
      else
        errorMsgSet("Nous ne trouvons pas le code postal")
    } else {
      errorMsgSet(false)
    }
  };

  const isFirstPartValid = () => {
    errorMsgSet(false);
    const u = tunnel.user || {};
    const errorsList = [];
    const fields = ["firstName", "lastName", "email"];
    if( !C.noGender) {
      fields.push('gender');
    }
    fields.forEach(f => {
      if (!u[f] && errorsList.indexOf(f) < 0)
        errorsList.push(f);
    });
    if( C?.optin?.step === 1 && !tunnel.user?.optin ){
      errorsList.push('optin')
    }
    if( !u.phone || !isValidPhoneNumber(u.phone)) {
      alert('Rentrez le numéro au format international')
      errorsList.push('phone')
    }
    setErrors(errorsList);

    return (!errorsList.length);
  };

  const isSecondPartValid = () => {
    errorMsgSet(false);
    const u = tunnel.user || {};
    const errorsList = [];

    if( !C.noAddress) {
      ["addressFull", "address", "zipcode", "city", "country"].forEach(f => {
        if (!u[f] ) {
          errorsList.push("address");
          errorMsgSet("L'adresse est manquante ou ne contient pas de numéro dans la rue")
        }
      });
    }

    if( !C.noBirthdate && !u.birthdate ) {
      errorsList.push('birthdate')
    }
    if( C?.optin?.step === 2 && !tunnel.user?.optin ){
      errorsList.push('optin')
    }
    
    if ( C.minAge) {
      const age = dayjs(new Date()).diff(u.birthdate, 'year');
      if ( age < C.minAge.value) {
        errorMsgSet(C.minAge.text);
        errorsList.push('birthdate');
      }
    }
    setErrors(errorsList);
    return (!errorsList.length);
  };

  const doCreateSale = async (contact) => {
    const previousStep = step;
    errorMsgSet(false);
    setStep("CREATING_SALE")
    return createSale(contact)
    .then(ret => {
      if( config.recap) {
        setStep("RECAP");
        return true;
      }
      setStep('SEPA');
      return true;
    })
    .catch(err => {
      errorMsgSet(config.errorCode[err.errorCode] || err.userMsg || "Une erreur est survenue");
      setStep(previousStep)
      console.error(err);
      return false;
    });
  };

  const submit = async () => {
    setStep("LOADING");
    errorMsgSet(null);
    setErrors([]);

    if( tunnel.contactResa && !tunnel.user.hasChanged ) {
      if (config.plans.beforeUser) {
        return doCreateSale();
      } else {
        setStep('NEXT');
      }
      window.scrollTo(0, 0)
      return;
    }

    const [leadQuery] = Api.POST({
      path: '/api/abo/lead',
      body: {
        place: {
          id: tunnel.club.msdsId,
        },
        client: {
          firstname: tunnel.user.firstName,
          lastname: tunnel.user.lastName,
          email: tunnel.user.email,
          phone: tunnel.user.phone,
        },
        source: "MSDS_R2_VEL",
        source_url: window.location.href
      }
    })
    try {
      await leadQuery;
    } catch(err) {
      console.error(err);
    }

    const apiBody = {
      tunnelUid: tunnel.uid,
      msdsId: tunnel.club.msdsId,
      user: {
        lastname: tunnel.user.lastName,
        firstname: tunnel.user.firstName,
        phone: tunnel.user.phone,
        email: tunnel.user.email,
        
      }
    };
    if( !C.noAddress ) {
      apiBody.user.address = {
        streetAddress: `${tunnel.user.address} ${!!tunnel.user.address2 ? tunnel.user.address2 : ''}`.trim(),
        postcode: tunnel.user.zipcode,
        city: tunnel.user.city,
        country: tunnel.user.country,
      }
    }
    if( !C.noGender ) {
      apiBody.user.gender = tunnel.user.gender;
    }
    if( !C.noBirthdate ) {
      apiBody.user.birthdate = tunnel.user.birthdate;
    }
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/contact',
      body: apiBody
    });
    query
    .then(({contact}) => {
      updateTunnelContactResa(contact);
      try {
        localStorage.setItem('user', JSON.stringify(tunnel.user))
      } catch(e) {
        //Don't care
      }
      if (config.plans.beforeUser) {
        console.debug('doCreateSale with', contact)
        return doCreateSale(contact)
        .then( () => {
          console.log('doCreateSale has this contactResa', tunnel.contactResa)
        })
      } else {
        setStep('NEXT');
      }
    })
    .catch(err => {
      setStep("FORM_1");
      errorMsgSet(err.userMsg || "Une erreur est survenue")
      console.error(err);
    });
  }

  const getError = (name) => (errors.indexOf(name) >= 0) ? "error" : "";

  const afterStep1 = () => {
    if( !C.noBirthdate || !C.noAddress ) {
      setStep("FORM_2")
    } else {
      submit();
    }
  }

  let nextStep = () => { throw new Error('No next step') };
  if( step === 'RECAP') {
    nextStep = () => setStep('SEPA');
  } else if ( ['CREATING_SALE', 'LOADING', 'FORM_1', 'FORM_2', 'LOADING', 'NEXT', 'SEPA'].indexOf(step) !== -1) {
    //There is no nextStep for thos
  } else if ( step === 'SEPA' ) {
    //There is no nextStep for thos
  } else {
    console.warn('Cannot create nextStep for step ===', step);
  }

  if ( step === 'NEXT')
    return <Redirect push to={'/plans'} />;

  if ( step === 'LOADING') {
      return <Step classe="UserForm" step="Vos coordonnées" loading={config.loadingTextUserCreation} style={{height:'100vh'}}/>;
  }

  if ( step === 'CREATING_SALE') {
      return <Step classe="Plans" step="Formules d'abonnement & options" loading={config.loadingTextCartCreation}/>
  }

  if( step === 'RECAP' ){
    return <CartSummary goNext={ nextStep } errorMsg={errorMsg} errorMsgSet={errorMsgSet}/>;
  }

  if (step === "SEPA"){
    return <Redirect push to={'/sepa'} />;
  }


  if ( step === 'CHECK_ADDRESS') {
      return <Step classe="UserForm" step="Vos coordonnées" loading={config.loadingTextAddressCheck} />;
  }
  if( step === 'FORM_1' ) {
    return (
      <Step classe="UserForm" step="Vos coordonnées" loading={false}>
        <header>
          <StepHeader text={C.title || "Vos coordonnées (1/2)"}/>
        </header>
        <div className={(!!offer || !!offer_url) ? 'split withOffer' : "split"}>
          {offer_url && <img src={offer_url} alt='offer' style={{marginRight:'auto', background:'white', border: '1px solid #B8B8B8'}}/> }
          {offer && !offer_url && <Choice value={offer} selected={[]} onSelect={null} useImage={config.plans.useImg} imageBaseURL={config.plans.imageBaseURL}/> }
          <form onSubmit={submit}>
            <fieldset>
             { !C.noGender && (
                <label className='labelSpe'>
                  <select className={getError("gender")} required onChange={onChange} name={"gender"} value={tunnel.user?.gender ?? ""}>
                    <option value={""} disabled>{C.genderLabel}</option>
                    <option value={"F"}>{C.gender_F}</option>
                    <option value={"M"}>{C.gender_M}</option>
                  </select>
                </label>
             )}
              <LabelInputText classError={getError("firstName")}  onChange={onChange} name="firstName" placeHolder={C.firstnameLabel} value={tunnel.user?.firstName}/>
              <LabelInputText classError={getError("lastName")}  onChange={onChange} name="lastName" placeHolder={C.lastnameLabel} value={tunnel.user?.lastName} />
              <LabelInputText classError={getError("email")}  onChange={onChange} name="email" type="email" placeHolder={C.emailLabel} value={tunnel.user?.email} />
              <div className="phoneWrapper">
                  <PhoneInput
                    name='phone'
                    control={control}
                    international
                    defaultCountry={tunnel?.club?.country}
                    countryCallingCodeEditable={false}
                    placeholder={C.phoneLabel}
                  />
                <em>{C.phoneExplain}</em>
              </div>
            </fieldset>
          </form>
          
        </div>
        <StyledStepFooter>
          {C?.optin?.step === 1 && 
              <Confirm className={`labelSpe ${getError("optin")}`} checked={!!tunnel.user?.optin} toggle={() => onChange({target:{name:'optin', value:!tunnel.user?.optin}})}>
                <p>
                  <span style={{fontStyle:'italic', fontSize:'0.875rem', fontWeight:300, lineHeight:'1.15rem'}}>
                    {C.optin.text}
                  </span>
                  <a style={{fontSize:'0.875rem', fontWeight:500, lineHeight:'1.15rem', paddingBottom:'0.5rem'}}
                    href={tunnel?.club?.optin_href || C.optin.href}
                    target="_blank" rel="noreferrer">
                    {C.optin.moreLabel}
                  </a>
                </p>
            </Confirm>
          }
          {!C.noClub && <ClubPreview club={tunnel.club}  style={{marginTop:0, paddingTop:0, marginBottom: "1rem"}}/> }
          {!!errorMsg && <p style={{textAlign:'center', fontWeight:'bold'}}>{errorMsg}</p>}
          { step !== "LOADING" && <Button variant='primary' onClick={() => isFirstPartValid() && afterStep1()}>{C.nextLabel}</Button>}
        </StyledStepFooter>
      </Step>
    );
  }

  if ( step === 'FORM_2') {
    return (
      // Offer Goal Conversion: CPL
      <Step classe="UserForm" step="Vos coordonnées" loading={false} style={{height:'100vh'}}>
        {C?.tracker && C.tracker(tunnel) }
        <header>
          <StepHeader text={C.title || "Vos coordonnées (2/2)"}/>
        </header>
        <form onSubmit={submit}>
          <fieldset>
            { !C.noBirthdate && (
              <label className='labelSpe'>
                <span>{C.birthdateLabel}</span>
                <input
                  className={getError("birthdate")}
                  onChange={onChange}
                  name={"birthdate"}
                  type="date"
                  value={tunnel.user?.birthdate ??""}
                />
              </label>
            )}
            { !C.noAddress && (
              <div className={getError("address")}>
                {/*  Language must be 'FR' cause the API wants country in french name*/}
                <AutoComplete
                  placeholder={tunnel.user?.addressFull || C.addressLabel}
                  apiKey={'AIzaSyAFPrGdjW4E9zth5knmPfVhwPyaNNDhn1Y'}
                  language='fr'
                  options={{
                    types: ['address'],
                    fields: ['address_components', 'formatted_address', 'types', 'icon', 'name']
                  }}
                  onPlaceSelected={(place) => addressSelected(place)}
                />
              </div>
            )}
            <Button style={{marginTop:'2rem'}} disabled={step === "LOADING"} onClick={() => setStep('FORM_1')}>{C.backLabel}</Button>
          </fieldset>
        </form>

        <StyledStepFooter>
          {C?.optin?.step !== 2 && !C.noClub && 
            <ClubPreview club={tunnel.club}  style={{marginTop:0, paddingTop:0, marginBottom: "1rem"}}/>
          }
          {C?.optin?.step === 2 && 
              <Confirm className={`labelSpe ${getError("optin")}`} checked={!!tunnel.user?.optin} toggle={() => onChange({target:{name:'optin', value:!tunnel.user?.optin}})}>
                <p>
                  <span style={{fontStyle:'italic', fontSize:'0.875rem', fontWeight:300, lineHeight:'1.15rem'}}>
                    {C.optin.text}
                  </span>
                  <a style={{fontSize:'0.875rem', fontWeight:500, lineHeight:'1.15rem', paddingBottom:'0.5rem'}}
                    href={tunnel?.club?.optin_href || C.optin.href}
                    target="_blank" rel="noreferrer">
                    {C.optin.moreLabel}
                  </a>
                </p>
            </Confirm>
          }
          {!!errorMsg && <p style={{textAlign:'center', fontWeight:'bold'}}>{errorMsg}</p>}
          { step !== "LOADING" && <Button variant='primary' onClick={() => isSecondPartValid() && submit()}>{C.nextLabel}</Button> }
        </StyledStepFooter>
      </Step>
    );
  }
}
