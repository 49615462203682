import React from 'react';
import styled from 'styled-components';
import devices from './devices';

export default function ClubPreview({club, style, sameLine=false, noAddress=false}){
  return (
    <ClubPreviewStyled style={style} sameLine={sameLine}>
      <figure>
        {club.imgUrl && <img src={club.imgUrl} alt={club.name}/>}
      </figure>
      <section>
        <h3>{club.name}</h3>
        {!noAddress && <address>
          <span>{club.address}</span>
          {!!club.address2 &&
            <span>{club.address2}</span>
          }
          <span>{`${club.zip} ${club.city}`}</span>
        </address>}
      </section>
    </ClubPreviewStyled>
  );
}

const ClubPreviewStyled = styled.article`
  flex-direction: row;
  background-color: #fff;
  ${props => {
    if( props.sameLine) {
      return `
          @media(${devices.tablet.query}) {
            flex-direction: column;
            flex-grow: 1;
          }
      `
    }
  }}
  figure {
    flex-shrink: 0;
    margin-right: .5rem;
    width: 6rem;
    height: 6rem;
    ${props => {
      if( props.sameLine) {
        return `
            @media(${devices.tablet.query}) {
              width: 100%;
              height: auto;
            }
        `
      }
    }}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  section {
/*    width: calc(100% - 7rem);*/
    padding: 1rem .5rem;
    ${props => {
      if( props.sameLine) {
        return `
            @media(${devices.tablet.query}) {
              display: flex;
              flex-grow:1;
              justify-content:space-between;
              width:100%;
            }
        `
      }
    }}

    h3 {
      font-weight: 800;
      line-height: 1.125rem;
      margin-bottom: .25rem;
    }
    address {
      font-style: normal;
      line-height: 1rem;
      font-size: .875rem;
      display: flex;
      flex-direction: column;

      span {
        overflow-wrap: break-word;
      }
    }
  }
`
