import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import TunnelContext from "./TunnelContext";
import ConfigProvider from './ConfigProvider';

import ClubSelection from "./steps/ClubSelection";
import UserForm from "./steps/UserForm";
import Plans from "./steps/Plans";
import Sepa from "./steps/Sepa";
import Card from "./steps/Card";
import Contract from "./steps/Contract";
import Confirmation from "./steps/Confirmation";



function App({config}) {
  return (
    <ConfigProvider config={config}>
      <ConfiguredApp />
    </ConfigProvider>
  );
}

export default App;

function ConfiguredApp(){
  return (
      <BrowserRouter>
        <Header/>
        <TunnelContext>
          <Switch>
            <Route exact path={`/`} component={ ClubSelection } />
            <Route exact path={`/user`} component={ UserForm } />
            <Route exact path={`/plans`} component={ Plans } />
            <Route exact path={`/sepa`} component={ Sepa } />
            <Route exact path={`/contract`} component={ Contract } />
            <Route exact path={`/card`} component={ Card } />
            <Route exact path={`/confirmation`} component={ Confirmation } />
            <Redirect from={'*'} to={`/`} />
          </Switch>
        </TunnelContext>
        <Footer/>
      </BrowserRouter>
  );
}
