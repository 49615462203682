export default {
	code: 'onair.es',
	appClassName: 'ONAIR',
	analytics: {
		gtag: 'G-YB9C0QF43J'
	},
	favicon: '/onair-favicon.png',
	title: "Me suscribo | ON AIR Fitness",
	companyName: "ON AIR Fitness",
	website: 'https://onair-fitness.fr',
	notifyChannel: 'onair-fitness',
	header: {
		title: "Me suscribo",
		logo: "/onair-logo--colored.jpeg"
	},
	footer: {
		logo: "/onair-logo--white.png",
		cgv: {
			link : 'https://www.onair-fitness.es/condiciones-generales-de-venta/',
			text: 'CGV'
		},
	},
	promoCode: {
		label: "¿Tiene un código promocional?",
		confirmLabel: "Validar",
		verifyingLabel: "Verificación...",
		placeholder: 'código',
	},
	payByCard: "STRIPE",
	paymentReinsurances: [
		"Pago seguro",
		"Mejor oferta garantizada",
		"14 días para cambiar de opinión antes de comenzar el entrenamiento",
	],
	choice: {
		selectLabel: 'Seleccionar',
		selectedLabel: 'Seleccionada',
		mandatoryLabel: "Obligatorio",
		nameColor: '#AE1D22',
		border: 'lightgray',
		border_selected: 'black',
		background: 'white',
		footerBg: '#ededed',
		footerBg_selected: '#F7F7F7',
		checkboxSelected: '#AE1D22'
	},
	sign : {
		title: 'Firma del contrato',
		placeholder: "Código recibido por SMS",
		explain: "Ingrese aquí el código recibido por SMS para firmar el contrato",
		resendCodeLabel: "Reenviar el código",
		badCodeLabel: "Código incorrecto",
		signLabel: "Validar la firma",
	},
	payment: {
		loadingLabel: "Verificación en curso",
		informationLabel: "Información de pago",
		resumeLabel: "Total a pagar con tarjeta: ",
		payLabel: "Pagar ",
		errorLabel: "Tenemos un problema con su pago.",
		errorTitle: "Se ha producido un error.",
	},
	loadingDefault: "Cargando",
	loadingSmsCodeCheck: 'Verificación del código',
	loadingSmsCodeSend: 'Envío del SMS',
	loadingTextUserCreation: "Creación de tu cuenta",
	loadingTextCartCreation: "Creación de tu carrito",
	loadingTextAddressCheck: "Verificación de tu dirección",
	loadingTextOffersLoad: "Cargando las ofertas",
	loadingTextContractCreation: "Creación del contrato...",
	errorCode: {
		"api.error.sale.contact-invalid-data":"Por favor, intente con otra dirección de correo electrónico o póngase en contacto directamente con el club.",
		default: "Se ha producido un error con Resamania2",
	},
	plans: {
		title: "Elige tu plan",
		incitation: "Selecciona una opción",
		feesLabel: "Cuota de inscripción",
		nofeesLabel: "Sin cuota de inscripción",
		noPlanFoundTitle: "No se encontró ninguna opción",
		nextLabel: "Siguiente",
		noPlanFoundTexts : [
			"No hemos encontrado ninguna opción para este club,",
			"por favor inténtalo de nuevo más tarde o cambia de club en el paso 1."
		],
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
	},
	productsLoader : {
		contextFilter: 'member.shop',
		errorTitle: "Se ha producido un error con Resamania2",
		errorText: "Por favor, inténtalo de nuevo más tarde o con otro club.",
	},
	options: {
		mandatoriesAreExcludedFromFreeChoice: true,
		title: "Seleccione sus opciones",
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
		message: "¿Tienes alguna duda sobre la elección de tus opciones? No te preocupes, podrás obtener información más adelante directamente en la recepción de tu club.",
		nextLabel: "Siguiente",
		desktopInlineBy3: true,
	},
	regulars : {
		title: "Tienda",
		nextLabel: "Siguiente",
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
		message: "¡Los productos que hayas elegido estarán disponibles en la recepción de tu club cuando vengas para tu primera sesión!"
	},
	recap: {
		title: "Resumen",
		myOffer: "Mi oferta",
		myOption: "Mis opciones",
		myCounter: "Mis compras",
		nextLabel: "Confirmo",
		payByCardLabel: "Total a pagar con tarjeta",
		payBySepaLabel : "Total prélevé directement", //TODO
	},
	sepa : {
		noBic: true,
		title: "RIB - PAGO MENSUAL",
		validateLabel : "Por la presente, acepto los cobros automáticos SEPA.",
		explain: "Por favor, introduzca su número de cuenta bancaria (RIB) a continuación para validar su suscripción y firmar su mandato de domiciliación SEPA",
		ownerLabel: "Titular de la cuenta bancaria",
		ibanLabel: "IBAN",
		bicLabel: "BIC",
		bankLabel: "Nombre del banco",
	},
	contract: {
		title: "Su contrato", //Votre contract
		signLabel: "Firmar el contrato", //Signer le contrat
	},
	confirmation: {
		welcomeLabel: "Bienvenido a",
		text: "Su afiliación ha sido registrada correctamente. Recibirá un correo electrónico de confirmación en breve.",
		backLabel: "Volver al sitio web.",
		tracker: (tunnel) => !!tunnel && <img src={`https://sekoya.go2cloud.org/aff_goal?a=l&goal_name=cpa&adv_id=527&offer_id=173 &adv_sub=${tunnel.uid}&conversion_unique_id=173-${tunnel.uid}`} width="1" height="1" />,
	},
	steps: {
		display: {
			showShortNames: false
		},
		clubSelect: {
			title: 'SU CLUB',
			withMap: false,
			searchable:false,
			showOnly: 3,
			showMap: "Ver el mapa",
			hideMap: "Ocultar el mapa",
			nextLabel: "Siguiente",
			confirm: {
				txt: "Al marcar esta casilla, confirmo que deseo inscribirme en el club seleccionado como mi club de entrenamiento principal."
			},
		},
		userForm: {
			displayFirstOffer: true,
			title: "Sus datos de contacto",
			nextLabel: "Siguiente",
			genderLabel: 'Género',
			gender_F: "Mujer",
			gender_M: "Hombre",
			firstnameLabel: 'Nombre',
			lastnameLabel: "Apellido",
			emailLabel: 'Email',
			phoneLabel: "Número de teléfono móvil",
			phoneExplain: "Utilizado para la firma del contrato",
			birthdateLabel: "Fecha de nacimiento",
			addressLabel: "Dirección",
			backLabel: "Volver",
			optin: {
				text: "Acepto que ON AIR utilice mis datos para mejorar mi experiencia de entrenamiento, enviarme mensajes de motivación e informarme sobre la vida del club.",
				href: 'https://www.onair-fitness.es/politica-de-privacidad/',
				moreLabel: "Más información",
				step: 2,
			},
			minAge: {
				value: 18,
				text: "Si tienes menos de 18 años, te invitamos a que te acerques directamente al club para realizar tu inscripción."
			},
			tracker: (tunnel) => !!tunnel && <img src={`https://sekoya.go2cloud.org/aff_goal?a=l&goal_name=cpl&adv_id=578&offer_id=173&adv_sub=${tunnel.uid}`} width="1" height="1" />
		},
	},
	rootProperties: {
		'primary': '#AE1D22',
		'primary--dark': 'darken(#AE1D22, 15)',
		'placeholder': '#999999',
		'border': '#3d3d3d',
	},
	htmlStyle: `
		html {
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
		}
	`,
	theme: {
		primary: '#AE1D22',
		fontFamily: "Montserrat, sans-serif",
		buttonPrimary: {
			color: '#AE1D22',
			text: '#fff',
		},
		buttonOther: {
			color: 'black',
			text: '#fff',
		},
		buttonSecondary: {
			color: "black",
			text: '#fff'
		},
		stepHeader: {
			fontWeight: 800,
			fontSize: "1.25rem",
			textTransform: 'uppercase',
			paddingTop:'1rem'
		}
	}
};
