import styled from 'styled-components';
import {useConfig} from './ConfigProvider';

export default function CheckMark() {
  const config = useConfig();

  return <StyledCheckMark theme={config.theme}/>
}

const StyledCheckMark = styled.span`
  position: relative;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${ (props) => props.theme.primary };
  display: inline-block;
  margin-right: 16px;
  
  &:before {
    content: '';
    color: ${ (props) => props.theme.primary };
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 5px;
    border-color: ${ (props) => props.theme.primary };
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(-45deg);
  }
`;
