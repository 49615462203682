import styled from 'styled-components';
import devices from './devices';

const StyledStepFooter = styled.footer`
	position: sticky;
	bottom: 0;
	background-color: #ffffff;
	padding: 1.5rem;
	margin-top: auto;
	@media(${devices.tablet.query}) {
		padding: 2rem calc((100% - ${devices.tablet.size}) / 2 + 1.5rem);
	}

`;

export default StyledStepFooter;