const devices = {
  tablet: '720px',
};

Object.entries(devices).forEach( ([type, size]) => {
  devices[type] = {
    size,
    query:`(min-width: ${size})`
  };
})

export default devices;