import React, {useEffect} from "react";
import {useConfig} from '../ConfigProvider';
import {useTunnelData} from "../TunnelContext";
import Button from "../Button";
import StepHeader from "../StepHeader";
import Step from './Step';
import CounterChoice from '../CounterChoice';
import "./Plans.scss";
import StyledStepFooter from '../StyledStepFooter';

export default function RegularsSelect ({choices, goNext, errorMsg, errorMsgSet}) {
  const config = useConfig();
  const {tunnel, setTunnelRegulars} = useTunnelData();

  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);

  const add = (regular, value) => {
    errorMsgSet(false);
    const regulars = {...tunnel.regulars};
    regulars[regular['@id']] = regulars[regular['@id']] || {
      regular: regular,
      count: 0
    };
    regulars[regular['@id']].count = Math.max(0, regulars[regular['@id']].count + value);

    setTunnelRegulars(regulars);
  };

  return (
    <Step classe="Plans" step="Formules d'abonnement & options">
      <header style={{display:'flex', flexDirection:'column'}}>
        <StepHeader text={config.regulars.title}/>
        <small style={{marginTop:'1rem', fontStyle:'italic'}}>{config.regulars.message}</small>
      </header>
      <ul className="OptionsList">
        {choices.map((regular, i) =>
          <li key={`regular_${i}`}>
            <CounterChoice value={regular} selected={tunnel.regulars} add={add} useImage={config.regulars.useImg} imageBaseURL={config.regulars.imageBaseURL}/>
          </li>
        )}
      </ul>

      <StyledStepFooter>
        {!!errorMsg && <p style={{textAlign:'center', fontWeight:'bold'}}>{errorMsg}</p>}
        <Button onClick={goNext} variant='primary'>{config.regulars.nextLabel}</Button>
      </StyledStepFooter>
    </Step>
  );
}
