import React, { useState} from "react";

import Api from "../baseApi";
import {useTunnelData} from "../TunnelContext";
import Button from "../Button";
import LabelInputText from '../LabelInputText'
import {useConfig} from '../ConfigProvider';

import "./Plans.scss";

export default function PromoCode() {
  const {tunnel, setTunnel} = useTunnelData();
  const [code, codeSet] = useState('');
  const [loading, loadingSet] = useState(false);
  const [error, errorSet] = useState(null);
  const config = useConfig();

  const codeChanged = code => {
    if ( code ) {
      setTunnel({...tunnel, promo:code});
    } else {
      setTunnel({...tunnel, promo:undefined});
    }
  };

  const onChange = e => {
    errorSet(null);
    codeSet(e.target.value.trim().toUpperCase());
  }

  const _onSubmit = () => {
    errorSet(null);
    loadingSet(true);
    const [query] = Api.GET({
      path: `/api/abo/resamania2/promo/${code}`,
      query: { msdsId: tunnel.club.msdsId, tunnelUid: tunnel.uid }
    });
    query
    .then(json => {
      const [addQuery] = Api.POST({
        path: `/api/abo/resamania2/promo/${code}`,
        body: {saleId: tunnel.sales['@id'], msdsId: tunnel.club.msdsId, tunnelUid: tunnel.uid}
      })
      return addQuery
      .then( () => {
        const [refreshQuery] = Api.GET({
          path: `/api/abo/resamania2/sales`,
          query: { msdsId: tunnel.club.msdsId, saleId: tunnel.sales['@id'] },
        });

        return refreshQuery
        .then( () => {
          loadingSet(false);
          codeChanged(json);
        })
        .catch( err => {
          loadingSet(false);
          console.error( err.userMsg || 'Une erreur est survenue' );
          errorSet(err.userMsg || "Une erreur est survenue");
        })
      })
      .catch( err => {
        loadingSet(false);
        console.error( err.userMsg || 'Une erreur est survenue' );
        errorSet( err.userMsg || 'Une erreur est survenue' );
      })
    })
    .catch(err => {
      loadingSet(false);
      errorSet( err.userMsg || 'Une erreur est survenue' );
    });
  };
  if( tunnel.promo ) {
    return (
      <aside className='PromoCode'>
        <h4>Code: {tunnel.promo.code}</h4>
      </aside>
    )
  }
  return (
    <form onSubmit={e=>{e.preventDefault();_onSubmit() }} className='PromoCode'>
      <h4>{config.promoCode.label}</h4>
      <div style={{alignItems:'baseline'}}>
        <LabelInputText onChange={onChange} name="promoCode" placeHolder={config.promoCode.placeholder} value={code}/>
        <Button disabled={loading || !code.length} onClick={_onSubmit} type="submit" style={{marginLeft:'1rem', marginBottom:'1rem'}}>
          {!loading ? config.promoCode.confirmLabel : config.promoCode.verifyingLabel}
        </Button>
        <p style={{color:"red"}}>{error}</p>
      </div>
    </form>
  );
}
