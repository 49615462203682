import {useEffect} from 'react';

import Loader from "../Loader";
import StepsDisplay from "../StepsDisplay";

export default function Step({classe, step, loading, style={}, children}) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <main className={classe} style={style}>
      <StepsDisplay step={step} disabled={!!loading}/>
      {!!loading && <Loader text={loading}/>}
      {!loading && children}
    </main>
  );
}