import {useEffect} from 'react';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, useMap} from 'react-leaflet';

import ClubPreview from "./ClubPreview";

import "leaflet/dist/leaflet.css";
import "./ClubsMap.scss";

function MapController({clubs, center}) {
  const map = useMap();

  useEffect( () => {
    if( ! map )
      return;

    if(center){
      map.setZoom(11);
      return;
    }
    map.fitBounds( clubs.map( c => [c.lat, c.long] ))
  }, [map, clubs, center])

  return null;
}

const ClubsMap = ({clubs, selected, tunnel, center, onSelect}) => {
  return (
    <>
      <MapContainer className="ClubsMap" center={center} zoom={6}>
        <MapController clubs={clubs} center={center} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!!clubs.length && clubs.map((c, i) => {
          const iconUrl = (c.msdsId === selected?.msdsId)
            ? 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png'
            : 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png'
          ;
          return (
            <Marker
              icon={
                new L.icon({
                  iconUrl,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                })
              }
              key={`club_${c.msdsId}_${i}`}
              position={[c.lat, c.long]}
              eventHandlers={{
                click: (e) => onSelect(c)
              }}
            />
          );
        }
        )}
      </MapContainer>
      {!!tunnel.club &&
        <aside style={{margin:'auto'}}>
          <ClubPreview club={tunnel.club}/>
        </aside>
      }
    </>
  );
}

export default ClubsMap;