import styled from 'styled-components';
import {useConfig} from './ConfigProvider';

export default function Loader({text}){
  const content = text || "Chargement en cours...";
  const {theme} = useConfig();

  return (
    <StyledLoader theme={theme}>
      <span className='spin'/>
      <strong>
        {content}
      </strong>
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  strong {
    font-weight: 500;
    padding: 1.5rem;
  }
  .spin {
    width: 3rem;
    height: 3rem;
    border: 3px solid ${props => props.theme.loader?.border || 'black'};
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    &::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      border: 5px solid transparent;
      border-bottom-color: ${props => props.theme.loader?.border2 || '#e63f49'};
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
