import React, {useEffect} from "react";
import {useConfig} from '../ConfigProvider';
import {useTunnelData} from "../TunnelContext";
import Button from "../Button";
import StepHeader from "../StepHeader";
import Step from './Step';
import Choice from '../Choice';
import "./Plans.scss";
import StyledStepFooter from '../StyledStepFooter';

export default function OptionsSelect ({choices, goNext, errorMsg, errorMsgSet}) {
  const config = useConfig();
  const {tunnel, setTunnelOptions} = useTunnelData();

  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);

  const switchOption = (option) => {
    errorMsgSet(false);
    const o = [...tunnel.options];

    const found = o.findIndex(f => f['@id'] === option['@id']);
    if (found >= 0) {
      o.splice(found, 1);
      setTunnelOptions(o);
    } else {
      setTunnelOptions([...o, option]);
    }
  };

  const filteredChoices = choices.filter( o => {
    if( !config.options.mandatoriesAreExcludedFromFreeChoice )
      return true;

    const accepted = !o.mandatoryFor.length || o.mandatoryFor.indexOf(tunnel.offer['@id']) !== -1;
    if( !accepted ) {
      console.debug("option", o.name, 'removed from choice cause not mandatory for this one but for some others plans');
      return false;
    }

    return true;
  });

  let className = 'OptionsList';
  if( config.options.desktopInlineBy3 ) {
    className += ' desktopInlineBy3';
  }

  return (
    <Step classe="Plans" step="Formules d'abonnement & options">
      <header style={{display:'flex', flexDirection:'column'}}>
        <StepHeader text={config.options.title}/>
        <small style={{marginTop:'1rem', fontStyle:'italic'}}>{config.options.message}</small>
      </header>
      <ul className={className}>
        {filteredChoices.map((option, i) =>
          <li key={`option_${i}`}>
            <Choice value={option} selected={tunnel.options} onSelect={switchOption} useImage={config.options.useImg} imageBaseURL={config.options.imageBaseURL}/>
          </li>
        )}
      </ul>

      <StyledStepFooter>
        {!!errorMsg && <p style={{textAlign:'center', fontWeight:'bold'}}>{errorMsg}</p>}
        <Button onClick={goNext} variant='primary'>{config.options.nextLabel}</Button>
      </StyledStepFooter>
    </Step>
  );
}
