import Loader from "./Loader";

import "./ButtonLoader.scss";

const ButtonLoader = ({text}) => {
  return (
    <button className="ButtonLoader" disabled>
      <Loader text={text}/>
    </button>
  );
}

export default ButtonLoader;
