import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import {useConfig} from '../ConfigProvider';
import { useTunnelData } from "../TunnelContext";
import Api from "../baseApi";
import Button from "../Button";
import ButtonLoader from "../ButtonLoader";
import StyledStepFooter from '../StyledStepFooter';
import './Contract.scss';

export default function SignatureModal(){
  const { tunnel, setTunnel } = useTunnelData();
  const [ step, setStep ] = useState("FORM");
  const [ error, setError ] = useState(false);
  const config = useConfig();

  const sendSms = async () => {
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/sign_mandates',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        sign_mandate: {
          contactId: tunnel.contactResa['@id'],
          mandateId: tunnel.mandate['@id'],
          saleId: tunnel.sales['@id']
        },
      }
    });

    setError(false);
    setStep('SMS')
    return query
    .then( (ret) => {
      setTunnel({ ...tunnel, sign_request: ret });
      setStep('FORM');
    })
    .catch( e => {
      setError('Nous ne pouvons renvoyer le SMS');
      setStep('FORM');
    }) 
  };

  const handleChange = (e) => {
    setTunnel({ ...tunnel, signCode: e.target.value });
  };

  const submitCode = async (e) => {
    e.preventDefault();
    setStep("LOADING");
    setError(false);


    try {
      const [ signContractWithCode ] = Api.POST({
        path: '/api/abo/resamania2/end_sign_mandates',
        body: {
          tunnelUid: tunnel.uid,
          msdsId: tunnel.club.msdsId,
          tunnel: tunnel,
          end_sign_mandate: {
            code: tunnel.signCode,
            transition: "validate",
            signatureRequestId: tunnel.sign_request['@id']
          }
        }
      });
      
      await signContractWithCode;

      try {
        if( tunnel.sales.totalTI === 0 ) {
          await doPaymentManuelDues();
          setStep('CONFIRM')
          return;
        }
        if( !config.payByCard ) {
          await doPaymentManuelDues();
          setStep('CONFIRM')
          return;
        }
        setStep("CARD");
      } catch(e) {
        console.error(e);
        setError("La finalisation du contrat a échouée");
        setStep("FORM");
      }
    } catch(e) {
      console.error(e);
      setError("Le code SMS n'est pas valide");
      setStep("FORM");
    }
  };

  const doPaymentManuelDues = async () => {
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/payment',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        payment: {
          saleId: tunnel.sales['@id'],
          contactId: tunnel.contactResa['@id'],
          contactFamilyName: tunnel.contactResa.familyName,
          contactGivenName: tunnel.contactResa.givenName,
          contactNumber: tunnel.contactResa.number,
          amount: tunnel.sales.totalTI,
          clubId: tunnel.sales.clubId,
          clubCode: tunnel.sales.clubCode,
          checkout: tunnel.sales.checkoutId
        },
        tunnel
      }
    })
    return query;
  };

  const isValid = () => {
    const p = tunnel.signCode;
    if (!p)
      return false;

    return true;
  }

  if (step === "CONFIRM")
    return <Redirect push to={'/confirmation'} />;

  if (step === "CARD")
    return <Redirect push to={'/card'} />;

  return (
    <aside className="SignatureModal">
      <h3>{config.sign.title}</h3>
      <p>{config.sign.explain}</p>
      <form onSubmit={submitCode}>
        <label>
          <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
            <input autoFocus={true} type="text" placeholder={config.sign.placeholder} name="code" onChange={handleChange} />
            { step === "FORM" && <Button style={{border:0, margin:'auto'}} onClick={sendSms}>{config.sign.resendCodeLabel}</Button> }
          </div>
          {!!error.code &&
            <span className="form-error">{config.sign.badCodeLabel}</span>
          }
        </label>
      </form>
      <StyledStepFooter>
        {error && <p style={{color:"red"}}>{error}</p>}
        { step === "LOADING" && <ButtonLoader text={config.loadingSmsCodeCheck}/>  }
        { step === "SMS" && <ButtonLoader text={config.loadingSmsCodeSend}/>  }
        { step === "FORM" && <Button variant='primary' onClick={submitCode} disabled={!isValid()}>{config.sign.signLabel}</Button>}
      </StyledStepFooter>
    </aside>
  );
}
