import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import {useConfig} from '../ConfigProvider';
import { useTunnelData } from "../TunnelContext";
import StepsDisplay from "../StepsDisplay";
import Loader from "../Loader";
import Api from "../baseApi";
import Button from "../Button";
import SignatureModal from './SignatureModal';
import StyledStepFooter from '../StyledStepFooter';
import './Contract.scss';

export default function Contract(){
  const config = useConfig();
  const { tunnel, setTunnel } = useTunnelData();
  const [ contract, setContract ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ step, setStep ] = useState("CONTRACT");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (!tunnel.club || !tunnel.sales) return ;

    setError(false);
    const [ query ] = Api.GET({
      path: '/api/abo/resamania2/contract',
      query: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        sale: tunnel.sales['@id']
      }
    });

    query.then(ret => setContract(ret.contract) )
    .catch( e=> {
      console.error(e);
      setError('Nous ne pouvons afficher le contrat')
    });

  }, [ tunnel.sales, tunnel.club ]);

  const sendSms = async () => {
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/sign_mandates',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        sign_mandate: {
          contactId: tunnel.mandate.contactId,
          mandateId: tunnel.mandate['@id'],
          saleId: tunnel.sales['@id']
        }
      }
    });
    return query;
  };

  const askToSign = (e) => {
    e.preventDefault();

    setStep("SMS");
    setError(false);

    return sendSms()
    .then( ret => {
      setTunnel({ ...tunnel, sign_request: ret });
      setStep("SIGNMODAL");
    })
    .catch( e => {
      console.error(e);
      setError("Problème d'envoi SMS");
      setStep("CONTRACT");
    })
  };

  if (!tunnel.club || !tunnel.sales) return <Redirect push to={'/'} />;


  if (step === "SMS")
    return <Loader text={config.loadingSmsCodeSend} />;

  if( step === "SIGNMODAL" ) {
    return (
      <main className="Contract">
        <SignatureModal  />
      </main>
    );
  }

  return (
    <main className="Contract">
      <StepsDisplay step="Contrat"/>
      <header>
        <h2>{config.contract.title}</h2>
      </header>
      {!!contract ?
        <section>
          <iframe title={config.contract.title} src={'data:application/pdf;base64,' + contract} />
        </section>
        :
        <Loader/>
      }
      <StyledStepFooter>
        {error && <p style={{color:"red"}}>{error}</p>}
        <Button variant='primary' onClick={askToSign}>{config.contract.signLabel}</Button>
      </StyledStepFooter>
    </main>
  );
}
