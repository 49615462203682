import {useState} from "react";

import baseApi from "./baseApi";
import Button from "./Button";

import "./GeoSearch.scss";

export default function GeoSearch({geoChange, departmentChange}){
  const [geoActive, geoActiveSet] = useState(false);
  const [zipCode, zipCodeSet] = useState('');
  const [zipCodeError, zipCodeErrorSet] = useState(false);

  const searchByZip = () => {
    if (zipCode === ''){
      departmentChange(null);
      return;
    }
    zipCodeErrorSet(false);
    if( zipCode.length <= 4 ) {
      departmentChange(zipCode);
      return;
    }
    const [query] = baseApi.GET({
      path: "/search",
      origin: "https://nominatim.openstreetmap.org",
      query: {postalcode: zipCode, format: 'jsonv2', country: 'France'},
      headers: {'content-type': 'application/json', 'access-control-allow-methods': 'OPTIONS,GET',  'access-control-allow-origin': '*'}
    })
    query
    .then(results => {
      if (results.length < 1) {
        zipCodeErrorSet(true)
        console.error("Error on finding location by zipCode:", zipCode);
        return;
      }
      geoChange({lat: +(results[0].lat), lng: +(results[0].lon)});
    })
    .catch( e => {
      console.error("Cannot search by zip", e)
      zipCodeErrorSet(true);
    })
  }

  const searchByGeoloc = () => {
    if (! ("geolocation" in navigator) ) {
      console.error("Geolocation is not available");
      return;
    }
    !geoActive && geoActiveSet(true)
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((pos) => {
        zipCodeSet('');
        zipCodeErrorSet(false)
        geoChange({lat: pos.coords.latitude, lng: pos.coords.longitude})
      }, reject);
    })
  };

  return (
    <form className='GeoSearch' onSubmit={(e) => {e.preventDefault(); searchByZip(zipCode)}}>
      <fieldset className={!!zipCodeError ? "error" : ""}>
        <input type="text" value={zipCode} onChange={ e=>{zipCodeSet(e.target.value.trim()); zipCodeErrorSet(false)}} placeholder="Code postal" autoComplete="postal-code" pattern="[0-9]*"/>
        <Button onClick={searchByGeoloc} type='button'>
          {geoActive
            ? <img src="/icons/geolocation--active.svg" alt="Geolocation" style={{width:'1.5rem'}}/>
            : <img src="/icons/geolocation.svg" alt="Geolocation" style={{width:'1.5rem'}}/>
          }
        </Button>
      </fieldset>
      <Button onClick={ () => searchByZip(zipCode)} variant="secondary">
        <span>Rechercher</span>
        <img src="/icons/search--white.svg" alt="Magnifying glass"/>
      </Button>
    </form>
  );
}
