import {useConfig} from './ConfigProvider';

import "./Header.scss";

const Header = () => {
  const config = useConfig();

  return (
    <header className="Header">
      <h1 style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: '.5rem 1rem'}}>
        <img src={config.header.logo} alt={config.companyName} style={{height: '4rem', marginRight: '1rem'}}/>
        <small style={{fontSize: '1.25rem', fontWeight: '800'}}>{config.header.title}</small>
      </h1>
    </header>
  );
}

export default Header;