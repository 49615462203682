import React from 'react';
import ReactDOM from 'react-dom/client';
import getConfig from './config/';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/reset.css';
import './styles/index.scss';

const config = getConfig();

if( config.htmlStyle ) {
  const htmlStyle = document.createElement('style');
  htmlStyle.innerHTML = config.htmlStyle;
  document.getElementsByTagName('head')[0].appendChild(htmlStyle);
}

const rootDom = document.getElementById('root');

//Not working
if( config.rootProperties ) {
  Object.entries(config.rootProperties).forEach( ([k,v]) => {
    rootDom.style.setProperty(k, v);
  })
}

console.debug('Using config',config)
if ( config.title ) {
  document.title = config.title;
} else {
  console.warn('No config.title !');
}
if( config.favicon ) {
  const favicon = document.createElement('link');
  favicon.rel = 'icon';
  favicon.href = config.favicon;
  document.getElementsByTagName('head')[0].appendChild(favicon);
} else {
  console.warn('No config.favicon !');
}

if( config.analytics?.gtag ) {
  const gtag1 = document.createElement('script');
  gtag1.src = `https://www.googletagmanager.com/gtag/js?id=${config.analytics.gtag}`;
  document.getElementsByTagName('head')[0].appendChild(gtag1);

  const gtag2 = document.createElement('script');
  gtag2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', "${config.analytics.gtag}");
  `;
  document.getElementsByTagName('head')[0].appendChild(gtag2);
}
rootDom.classList.add(config.appClassName);
const root = ReactDOM.createRoot(rootDom);

root.render(
  // <React.StrictMode>
    <App config={config}/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
