import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useConfig} from './ConfigProvider';
import devices from './devices';
import { darken } from 'polished'

//TODO fill the paths
const steps = [
  {txt:"Votre club", path:"/", short:"Club"},
  {txt:"Vos coordonnées", path:"/user", short: 'Contact'},
  {txt:"Formules d'abonnement & options", path:"/plans", short:"Formules"},
  {txt:"Contrat", path:"/contract", short:"Contrat"},
  {txt:"Informations de paiement", path:"/sepa", short: 'Paiement'},
];


const StepsDisplay = ({step, disabled}) => {
  const config = useConfig();

  let usedSteps = [...steps];
  if( config.plans.beforeUser) {
    usedSteps = [
      {txt:"Votre club", path:"/", short:"Club"},
      {txt:"Formules d'abonnement & options", path:"/plans", short:"Formules"},
      {txt:"Vos coordonnées", path:"/user", short: 'Contact'},
      {txt:"Contrat", path:"/contract", short:"Contrat"},
      {txt:"Informations de paiement", path:"/sepa", short: 'Paiement'},
    ]
  }
  if( config.onlyOneClub ) {
    usedSteps = usedSteps.slice(1);
  }

  const foundIndex = usedSteps.findIndex(f => f.txt === step);
  const history = useHistory();

  const onClick = (index, step) => {
    if( disabled )
      return false;
    if( index >= foundIndex )
      return null;
    if( !step.path) {
      console.warn('Missing path for step', step);
      return null;
    }
    history.push(step.path);
  }
  //TODO unclickable if there is a stripe payment or thing like that
  return (
    <StyledList theme={config.theme}>
      {usedSteps.map((s, i) => {
        const filled = (i <= foundIndex );
        
        const span = config.steps.display.showShortNames
          ? <span>{s.short}</span>
          : <span>&nbsp;</span>
        ;
        return (
          <StyledLi key={`step_${i}`} onClick={ e => onClick(i, s)} filled={filled} theme={config.theme}>
            <div>{i + 1}</div>
            {span}
          </StyledLi>
        )
      })}
    </StyledList>
  );
}

export default StepsDisplay;

const StyledList = styled.ol`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem 1.5rem;
  @media(${devices.tablet.query}) {
    padding: 1rem calc((100% - ${devices.tablet.size}) / 2 + 1.5rem);
  }
  &::before {
    content: "";
    width: calc(100% - 4rem);
    height: .45rem;
    background-color: ${props => props.theme.stepsDisplay?.color?.main ?? 'black'};
    position: absolute;
    left: 2rem;
    top: calc(45%);
    @media(${devices.tablet.query}) {
      top: calc(32%);
      width: calc(${devices.tablet.size} - 5rem);
      left: calc((100% - ${devices.tablet.size}) / 2 + 2rem);
    }
  }
`;

const StyledLi = styled.li`
  position: relative;
  flex-direction: column;
  align-items:center;
  div {
    height: 2.125rem;
    width: 2.125rem;
    border-radius: 100rem;
    border: 2px solid ${props => props.theme.stepsDisplay?.color?.main ?? 'black'};
    font-weight: 700;
    font-size: 1.375rem;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    ${props => {
      if(props.filled) {
        return `
          cursor: pointer;
          background-color: ${props.theme.stepsDisplay?.color?.bgFilled ?? 'black'};
          color: ${props.theme.stepsDisplay?.color?.textFilled ?? 'white'};
          &:hover{
            border-color: ${darken(0.15, props.theme.stepsDisplay?.color?.main ?? 'black')};
            background-color: ${darken(0.15, props.theme.stepsDisplay?.color?.bgFilled ?? 'black')};
          }

          pointer-events: all;
        `
      } else {
        return `
          color: ${props.theme.stepsDisplay?.color?.main ?? 'black'};
          background-color: ${props.theme.stepsDisplay?.color?.bg ?? 'white'};
        `
      }
    }}
  }
  span {
    margin-top: 0.5rem;
    display: none;
    color: ${props => props.theme.stepsDisplay?.color?.subText ?? 'black'};
    @media(${devices.tablet.query}) {
      display:block;
    }
    ${props => {
      if(props.filled) {
        return `font-weight:700;`
      }
    }}
  }
`; 
