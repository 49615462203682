import styled from 'styled-components';
import devices from './devices';
import {useConfig} from './ConfigProvider';
import ClubPreview from "./ClubPreview";

export default function ClubList({sameLine, clubs, selected, onSelect}){
	const config = useConfig();
	return (
		<StyledClubsList sameLine={sameLine}>
			{clubs.map( club =>{
				const isSelected = (selected?.msdsId === club.msdsId);
				return (
					<ClubsListItem key={club.msdsId} selected={isSelected} onClick={() => onSelect(club)} theme={config.theme} sameLine={sameLine}>
						<ClubPreview club={club} sameLine={!!config.steps.clubSelect.sameLine} noAddress={config.steps.clubSelect.noAddress}/>
					</ClubsListItem>
				);
			})}
		</StyledClubsList>
	);
}

const StyledClubsList = styled.ol`
	margin-top:1rem;
	padding: 0.5rem 1.5rem;
	${props => {
		if( props.sameLine) {
			return `
				@media(${devices.tablet.query}) {
					padding: 0rem calc((100% - ${devices.tablet.size}) / 2 + 1.5rem);
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
					column-gap: 1em;
				}
			`;
		} else {
			return `
				@media(${devices.tablet.query}) {
					padding: 0rem calc((100% - ${devices.tablet.size}) / 2 + 1.5rem);
				}
			`
		}
	}}
`;

const ClubsListItem = styled.li`
	margin-bottom: 1.0rem;
	border: 2px solid transparent;
	border-radius: .5rem;
	overflow: hidden;
	cursor: pointer;
	${props => {
		if( props.selected) {
			return `
				border-color: ${props.theme.primary ?? 'black'};
				cursor: default;
			`;
		}
	}}
`
