import {useEffect} from 'react';
import "./Confirmation.scss";
import {useConfig} from '../ConfigProvider';
import StyledStepFooter from '../StyledStepFooter';
import {useTunnelData} from "../TunnelContext";

export default function Confirmation(){
  const {tunnel} = useTunnelData();
  const config = useConfig();

  useEffect(() => {
    window.scrollTo(0, 0)
    if( tunnel.club?.fbq && window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, []);

  return (
    <main className="Confirmation">
      {config.confirmation.tracker && config.confirmation.tracker(tunnel) }
      <section>
        <h2>{config.confirmation.welcomeLabel} {config.companyName} !</h2>
        <img src="/icons/check.svg" alt="Success"/>
        <p>{config.confirmation.text}</p>
      </section>
      <StyledStepFooter>
        <a style={{
          background: config.theme.primary,
          color: "white",
          textAlign: "center",
          fontWeight: "bold",
          padding:'0.5rem'
        }}
        href={config.website}>{config.confirmation.backLabel}</a>
      </StyledStepFooter>
    </main>
  );
}




