export default {
	code: 'onefitnessclub.fr',
	appClassName: 'OFC',
	favicon: '/ofc-favicon.png',
	title: "Je m'abonne | One Fitness Club",
	companyName: "One Fitness Club",
	website: 'https://onefitnessclub.fr',
	notifyChannel: 'one-fitness-club',
	header: {
		title: "Je m'abonne",
		logo: "/ofc-logo--colored.svg"
	},
	footer: {
		logo: "/ofc-logo--white.svg"
	},
	choice: {
		selectLabel: 'Sélectionner',
		selectedLabel: 'Sélectionné',
		mandatoryLabel: "Obligatoire",
		nameColor: '#E63F49',
		border: '#EFECEC',
		border_selected: 'lightgray',
		background: 'white',
		footerBg: '#EFECEC',
		footerBg_selected: '#f7f7f7',
		checkboxSelected: '#E63F49'
	},
	promoCode: false,
	options: {
		title: "Choisissez vos options",
		message: "",
		nextLabel: "Suivant",
	},
	regulars : false,
	recap: {
		title: "Récapitulatif",
		myOffer: "Mon offre",
		myOption: "Mes options",
		myCounter: "Mes achats",
		nextLabel: "Je confirme",
		payByCardLabel: "Total à payer par carte",
		payBySepaLabel : "Total prélevé directement",
	},
	sepa : {
		title: "RIB - Paiement Mensuel",
		validateLabel : "J’accepte par la présente les prélèvements automatiques SEPA",
		explain: "Veuillez mettre votre RIB ci-dessous pour valider votre abonnement et signer votre mandat de prélèvement SEPA",
		ownerLabel: "Titulaire du compte",
		ibanLabel: "IBAN",
		bicLabel: "BIC",
		bankLabel: "Nom de la banque",
	},
	contract: {
		title: "Votre contrat",
		signLabel: "Signer le contrat",
	},
	confirmation: {
		welcomeLabel: "Bienvenue chez",
		text: "Votre adhésion a bien été prise en compte, vous recevrez un e-mail de confirmation sous peu.",
		backLabel: "Retour au site."
	},
	sign : {
		title: 'Signature du contrat',
		placeholder: "Code reçu par SMS",
		explain: "Entrer ici le code reçu par SMS pour signer le contrat",
		resendCodeLabel: "Renvoyer le code",
		badCodeLabel: "Code incorrect",
		signLabel: "Valider la signature",
	},
	payment: {
		loadingLabel: "Vérification en cours",
		informationLabel: "Informations de paiement",
		resumeLabel: "Total à payer par CB: ",
		payLabel: "Payer ",
		errorLabel: "Nous avons un soucis avec votre paiment.",
		errorTitle: "Une erreur est survenue",
	},
	loadingDefault: "Chargement en cours",
	loadingSmsCodeCheck: 'Vérification du code',
	loadingSmsCodeSend: 'Envoi du SMS',
	loadingTextUserCreation: "Création de votre compte",
	loadingTextCartCreation: "Création de votre panier",
	loadingTextAddressCheck: "Vérification de votre adresse",
	loadingTextOffersLoad: "Chargement des offres",
	errorCode: {
		"api.error.sale.contact-invalid-data":"Veuillez essayer avec une autre adresse mail ou contacter le club directement.",
		default: "Une erreur est survenue avec Resamania2",
	},
	plans: {
		title: "Choisissez votre formule",
		incitation: "Sélectionnez une formule",
		feesLabel: "Frais d'inscription",
		nofeesLabel: "Aucun frais d'inscription",
		nextLabel: "Suivant",
		noPlanFoundTitle: "Aucune formule",
		noPlanFoundTexts: [
			"Nous n'avons trouvé aucune formule pour ce club,",
			"veuillez réessayer plus tard ou changer de club via l'étape 1"
		]
	},
	productsLoader : {
		contextFilter: 'member.shop',
		errorTitle: "Une erreur est survenue avec Resamania2",
		errorText: 'Veuillez réessayer plus tard ou avec un autre club',
	},
	steps: {
		display: {
			showShortNames: true
		},
		clubSelect: {
			title: 'Choisissez votre club',
			showMap: "Masquer la carte",
			hideMap: "Voir la carte",
			nextLabel: "Suivant",
			// sameLine: true,
		},
		userForm: {
			nextLabel: "Suivant",
			genderLabel: 'Civilité',
			gender_F: "Mme",
			gender_M: "M.",
			firstnameLabel: 'Prénom',
			lastnameLabel: "Nom",
			emailLabel: 'Adresse e-mail',
			phoneLabel: "Nº de téléphone portable",
			phoneExplain: "Utilisé pour la signature du contrat et du mandat SEPA",
			birthdateLabel: "Date de naissance",
			addressLabel: "Adresse",
			backLabel: "Retour",
		}
	},
	htmlStyle: `
		html {
			font-weight: 300;
			font-family: 'Kanit', sans-serif;
		}
	`,
	theme : {
		fontFamily: "Montserrat, sans-serif",
		buttonPrimary: {
			color: '#e63f49',
			text: '#fff',
		},
		buttonOther: {
			color: 'black',
			text: '#fff',
		},
		buttonSecondary: {
			color: "black",
			text: '#fff'
		},
		stepHeader: {
			fontWeight: 600,
			fontSize: "1.25rem",
			paddingTop:'1rem'
		}
	}
};
