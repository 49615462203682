import React from 'react';
import {useConfig} from './ConfigProvider';
import Button from './Button';
import StepHeader from "./StepHeader";
import Step from './steps/Step';
import PromoCode from "./steps/PromoCode";
import {useTunnelData} from "./TunnelContext";
import ClubPreview from "./ClubPreview";
import StyledStepFooter from './StyledStepFooter';

import "./CartSummary.scss";

export default function CartSummary({goNext}) {
	const { tunnel } = useTunnelData();
	const config = useConfig();

	const regulars = Object.values(tunnel.regulars).filter(e=>e.count > 0);

	const rp = tunnel.sales['_products'];

	//TODO do not use tunnel.{offer, options, regulars} to find in sales._products
	//DO the opossite, find the products;
	const display = {
		offer: {name: tunnel.offer.name, article: rp.find(r => r.offerId === tunnel.offer.offer['@id'])},
		options: tunnel.options.map( o => {
			return {id: o['@id'], name: o.name, article: rp.find(r => r.offerId === o.offer['@id'])};
		}),
		regulars: regulars.map( ({count, regular}) => {
			return {id: regular['@id'], count: count, name: regular.name, article: rp.find(r => r.offerId === regular.offer['@id'])};
		})
	};

	return (
		<Step classe="Plans" step="Contrat">
			<header>
				<StepHeader text={config.recap.title}/>
			</header>
			<article className="CartSummary">
				<ClubPreview club={tunnel.club} />
				<section style={{marginTop:'1rem'}}>
					<h4>{config.recap.myOffer}</h4>
					<p style={{marginLeft:'1rem', display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
						<span>{display.offer.name}</span>
						<span>{display.offer.article && `${(display.offer.article.totalTI/100).toFixed(2)} €`}</span>
					</p>
					{config.recap.offerExplanation && <em style={{marginLeft:'1.5rem', marginTop:'0.5rem', fontSize:'0.8rem', fontStyle:'italic'}}>{config.recap.offerExplanation}</em>}
				</section>
				{!!display.options.length && (
					<section style={{marginTop:'1rem'}}>
						<h4>{config.recap.myOption}</h4>
						{display.options.map( o => {
							return (
								<p key={o.id} style={{marginLeft:'1rem', display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
									<span>{o.name}</span>
									<span>{o.article && `${(o.article.totalTI/100).toFixed(2)} €`}</span>
								</p>
							);
						})}
					</section>
				)}
				{!!display.regulars.length && (
					<section style={{marginTop:'1rem'}}>
						<h4>{config.recap.myCounter}</h4>
						{display.regulars.map( o => {
							return (
								<p key={o.id} style={{marginLeft:'1rem', display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
									<span style={{display:'flex', flexDirection:'row'}}><span style={{fontWeight:'bold'}}>{o.count} *</span>&nbsp;<span>{o.name}</span></span>
									<span>{o.article && `${(o.count * o.article.totalTI/100).toFixed(2)} €`}</span>
								</p>
							)
						})}
					</section>
				)}
				<hr style={{borderTop:'1px solid'}}/>
				<section style={{marginTop:'1rem'}}>
					<h4 style={{textAlign:'right'}}>{config.payByCard ? config.recap.payByCardLabel: config.recap.payBySepaLabel}: {(tunnel.sales.totalTI/100).toFixed(2)} €</h4>
				</section>
				<div>
					{!!config.promoCode && <PromoCode/>}
				</div>
			</article>
			<StyledStepFooter>
				<Button variant='primary' onClick={goNext}>{config.recap.nextLabel}</Button>
			</StyledStepFooter>
		</Step>
	);
}

