import React, { useEffect, useState } from 'react';
import { Redirect,useLocation } from "react-router-dom";

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useConfig} from '../ConfigProvider';
import StepsDisplay from "../StepsDisplay";
import Api from "../baseApi";
import { useTunnelData } from "../TunnelContext";
import Loader from "../Loader";
import Button from '../Button';
import "./Card.scss";
import ButtonLoader from "../ButtonLoader";
import StepHeader from "../StepHeader";
import Step from '../steps/Step';
import CheckMark from '../CheckMark';
import StyledStepFooter from '../StyledStepFooter';

/**
  Testing interactively
  When testing interactively, use a card number, such as 4242 4242 4242 4242. Enter the card number in the Dashboard or in any payment form.

  Use a valid future date, such as 12/34.
  Use any three-digit CVC (four digits for American Express cards).
  Use any value you like for other form fields.

  https://stripe.com/docs/testing
*/
export default function Card() {
  const config = useConfig();
  const { tunnel } = useTunnelData();

  if( !tunnel.sales ) {
    return <Redirect push to='/plans'/>
  }


  if( config.payByCard === 'STRIPE')
    return <CardStripe />

  if ( config.payByCard === 'PAYLINE')
    return <Payline/>
}

function Payline(){
  const { tunnel } = useTunnelData();
  const [ step, setStep ] = useState('init');
  const {search} = useLocation();

  const payWithPayline = () => {
    setStep('prepare')
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/payment/payline',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        payment: {
          contactId: tunnel.contactResa['@id'],
          saleId: tunnel.sales['@id'],
          amount: tunnel.sales.totalTI,
          clubId: tunnel.sales.clubId,
          clubCode: tunnel.sales.clubCode,
          checkoutId: tunnel.sales.checkoutId
        },
        origin: window.location.origin || window.origin,
        tunnel
      }
    });

    return query
    .then( ({redirectUrl}) => {
      window.location.href = redirectUrl;
    })
    .catch( e => {
      console.error(e);
      setStep('error')
    })
  }

  useEffect( () => {
    if( new URLSearchParams(search).has('direct') && step === 'init' ) {
      payWithPayline();
    } else {
      setStep('show');
    }
  }, [search]);

  if ( step === 'init' )
    return null;

  if( step === 'REDIRECT' ) {
    return <Redirect push to='/plans'/>
  }

  if( step === 'prepare') {
    return (
      <Step step="Informations de paiement" classe='ClubSelection' loading="Redirection sur le paiement sécurisé" />
    )
  }
  return (
    <Step step="Informations de paiement" classe='ClubSelection'>
      <header style={{margin:'auto'}}>
        <StepHeader text="Payer avec Payline"/>
      </header>
       <article className="CartSummary">
        <p>Votre inscription est effective, il ne vous reste plus qu'à régler par carte la somme de {(tunnel.sales.totalTI/100).toFixed(2)} €.</p>
      </article>
      <StyledStepFooter>
        <Button variant='primary' disabled={step==='prepare'} onClick={payWithPayline}>Je confirme</Button>
      </StyledStepFooter>
    </Step>
  )


}


function CardStripe() {
  const { tunnel, setTunnel } = useTunnelData();
  const [ step, setStep ] = useState('STRIPE_PREPARE');
  const config = useConfig();

  const doResaPayedByCard = () => {
    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/payedByCard',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        tunnel,
      }
    });

    return query
  };
  
  useEffect(() => {
    if( step === 'READY')
      return;

    if( tunnel.paymentIntent ) {
      setStep('VERIFY_PAYMENT');
      doResaPayedByCard()
      .then( () => {
        setStep("PAYMENT_CONFIRMED")
      })
      .catch( e => {
        setStep("PAYMENT_ERROR")
      })
      return;
    }

    if ( step === 'STRIPE_PREPARE') {
      const [ query ] = Api.POST({
        path: '/api/abo/stripe/paymentIntent',
        body: tunnel
      });

      query
      .then(ret => {
        setTunnel({
          ...tunnel,
          stripe: {
            ...ret,
            promise: loadStripe(ret.client_public)
          }
        });
        setStep('READY');
      })
      .catch(err => {
        console.error(err);
        setStep('ERROR');
      });
      return;
    }

    console.error('unknow step', step);
  }, []);



  if( step === 'VERIFY_PAYMENT') {
    return (
      <main className="Card">
        <StepsDisplay step={"Informations de paiement"}/>
        <header>
          <h2>{config.payment.loadingLabel}</h2>
        </header>
        <Loader/>
      </main>
    )
  }

  if (step === "PAYMENT_CONFIRMED")
    return <Redirect push to={'/confirmation'} />;

  if (step === "PAYMENT_ERROR"){
    return (
      <main className="Card">
        <StepsDisplay step={"Informations de paiement"}/>
        <header>
          <h2>{config.payment.errorTitle}</h2>
        </header>
        <p style={{margin:'auto'}}>{config.payment.errorLabel}</p>
      </main>
    )
  }

  if( step === 'ERROR') {
    return (
      <main className="Card">
        <StepsDisplay step={"Informations de paiement"}/>
        <header>
          <h2>{config.payment.errorTitle}</h2>
        </header>
        <p style={{margin:'auto'}}>Stripe n'est pas configuré pour ce club</p>
      </main>
    )
  }

  if( step === 'STRIPE_PREPARE') {
    return (
      <main className="Card">
        <StepsDisplay step={"Informations de paiement"}/>
        <header>
          <h2>{config.payment.informationLabel}</h2>
        </header>
        <Loader text={config.loadingDefault}/>
      </main>
    )
  }

  if( step === 'READY') {
    return <CardWrapper />;
  }

};


const CardWrapper = () => {
  const { tunnel } = useTunnelData();
  
  const options = {
    clientSecret: tunnel.stripe.client_secret,
    locale: 'fr'
  };

  return (
    <Elements stripe={tunnel.stripe.promise} options={options}>
      <CardView  />
    </Elements>
  );
};

const CardView = () => {
  const config = useConfig();
  const { tunnel } = useTunnelData();
  const [ step, setStep ] = useState("INITIAL");
  const [cbError, setCbError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStep("PENDING");
    setCbError(null);
    if (!stripe || !elements) return;

    const body = {
      elements,
      confirmParams: {
        return_url: window.location.origin + `/card?tunnelId=${tunnel.stripe.id}`
        // return_url: window.location.origin + `/sepa?tunnelId=${tunnel.stripe.id}`
      }
    };

    const result = await stripe.confirmPayment(body);
    if (result.error) {
      setCbError(result.error.message);
      setStep("INITIAL");
      //WHY??
      //if (result.error.payment_intent.status === 'succeeded') return setStep('SEPA');
    }
  };

  return (
    <main className="Card">
      <StepsDisplay step={"Informations de paiement"}/>
      <header>

        <h2>{config.payment.resumeLabel}{(tunnel.sales.totalTI / 100).toFixed(2)} €</h2>
      </header>

      <section>
        <form>
          <PaymentElement/>
        </form>
        {!!config.paymentReinsurances && (
          <ul style={{marginTop:'3rem'}}>
          {config.paymentReinsurances.map( (r, i) => {
            return (
              <li key={i}>
                <p style={{display:'flex', flexDirection:'row'}}>
                  <CheckMark />
                  {r}
                </p>
              </li>
            );
          })}
          </ul>
        )}
      </section>

      <aside>
        <div style={{display:'flex', alignItems:'center', marginBottom:'1rem'}}>
          <img src = 'stripe.png' alt='Stripe' width='auto' height='20px'/>
          <img src = 'payment-secure.png' alt='payment sécurisé' width='auto' height='25px'/>
        </div>
        {!!cbError && <em>{cbError}</em>}
        {step === "PENDING" ?
          <ButtonLoader text={config.loadingDefault}/> :
          <Button variant='primary' type="button" onClick={handleSubmit} disabled={!stripe}>
            {config.payment.payLabel} {(tunnel.sales.totalTI / 100).toFixed(2)} €
          </Button>
        }
      </aside>
    </main>
  );
}
